import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import { useSelector } from "react-redux";

import Navbar from "../components/Navbar";
import PatientData from "../components/PatientData";
import ProgressBar from "../components/ProgressBar";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { IoClose } from 'react-icons/io5';
import { IoCloseCircle } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch } from "../redux/store";
import { requestStudiesList, requestReport, updateDashboardStudy } from "../redux/OsteroCheckSlice";
import { IoCheckmarkCircle } from "react-icons/io5";
import Notavailable from '../assets/no-image.png'
import { MdOutlineQuestionMark } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { FaShare, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { surgical_data } from "../constant";

const Reports = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [matchingDatavalues, setMatchingDatavalues] = useState(null);
  const [extractedValue, setExtractedValue] = useState(null);
  const appDispatch = useAppDispatch();
  const [history, sethistory] = useState('')
  const [selected, setSelected] = useState("fullReport");
  const [comments, setComments] = useState('');
  const [selectedStudyId, setSelectedStudyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [localPatientData, setLocalPatientData] = useState(null);
  const patientData = useSelector(
    (state) => state.OsteroCheck.patientData
  );
  const jsonData = patientData.data?.json;
  const reasonData = useSelector((state) => state.reportBuilder.reasonData);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const mod_study = useSelector((state) => state.OsteroCheck.patientList)
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const shouldRender = location.pathname === '/reports' && !location.search;





  const toggleDropdown = () => setIsOpen(!isOpen);

  console.log("jsondata", patientData)
  const pathologiesData = patientData?.data?.json?.pathologies?.[0];
  console.log("pathologiesdata", pathologiesData);


  const truePathologies = pathologiesData
    ? Object.entries(pathologiesData)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key)
    : [];


  const isCompleted = location.pathname === '/reports' && location.search === '?type=completed';



  console.log("patientdata", mod_study)






  const studies = useSelector(
    (state) => state.OsteroCheck.patientList
  );


  useEffect(() => {
    if (patientData.data) {
      setLocalPatientData(patientData.data);
    }
  }, [patientData]);

  useEffect(() => {
    if (jsonData?.url) {
      const parts = jsonData.url.split('/');
      const lastPart = parts.pop()?.split('.')[0];
      sethistory(lastPart || '');
    } else {
      console.log("URL is undefined");
    }

    if (jsonData?.tabledata) {
      setTableData(jsonData.tabledata);
    }
  }, [jsonData]);

  const handleCheckClick = async (event) => {
    try {
      const button = event.currentTarget;
      const fillElement = button.querySelector('.fill-animation');
      fillElement.classList.add('active');

      const emergencyValue = patientData.data.action.emergency || false;

      const result = await appDispatch(updateDashboardStudy({
        study_id: patientData.data.order_id,
        action: { case: true, feedback: false, emergency: emergencyValue },
        comment: ''
      })).unwrap();

      setLocalPatientData(prevData => ({
        ...prevData,
        action: { ...prevData.action, case: true, feedback: false }
      }));

      toast.success('Case accepted successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      appDispatch(requestStudiesList());

      return { success: true, emergencyValue, result };
    } catch (error) {
      console.error('Failed to update study:', error);

      toast.error('Failed to accept case. Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      return { success: false, error };
    }
  };
  const handleSendFeedback = () => {
    setSelectedStudyId(patientData.data.order_id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStudyId(null);
    setComments('');
  };

  const handleSubmitComment = async () => {
    if (selectedStudyId && comments.trim()) {
      setIsSubmitting(true);
      setError(null);
      try {
        const emergencyValue = patientData.data.action.emergency || false;
        await appDispatch(updateDashboardStudy({
          study_id: selectedStudyId,
          action: { case: false, feedback: true, emergency: emergencyValue },
          comment: comments
        })).unwrap();
        closeModal();
        setLocalPatientData(prevData => ({
          ...prevData,
          action: { ...prevData.action, case: false, feedback: true }
        }));
        appDispatch(requestStudiesList())
      } catch (err) {
        setError("Failed to submit comment. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };


  const highlightPathologies = (sentence, pathologies) => {
    let highlightedSentence = sentence;
    pathologies.forEach(pathology => {
      const regex = new RegExp(`\\b${pathology}\\b`, 'gi');
      highlightedSentence = highlightedSentence.replace(regex, `<strong>${pathology}</strong>`);
    });
    return highlightedSentence;
  };


  const transformLabel = (label) => {
    switch (label) {
      case "ChestR":
        return "XRAY Radiograph Chest";
      case "SXR":
        return "XRAY Radiograph Spine";
      case "MRI spine":
        return "Contrast MRI Spine";
      case "CT brain":
        return "CT Brain Plain";
      case "KXR":
        return "XRAY Radiograph knee";
      case "mribrain":
        return "MRI Brain";
      case "lifesaver":
        return "Life Saver";
      default:
        return label;
    }
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const shareViaGmail = () => {
    const subject = 'Check this out!';
    const body = `I thought you might be interested in this:`;
    // window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const shareViaWhatsApp = () => {
    const text = `Check this out:`;
    // window.open(`https://wa.me/?text=${encodeURIComponent(text)}`, '_blank');
  };

  const parseJsonUrl = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON url string:", error);
      return [];
    }
  };


  const getExtractedValue = (url) => {
    if (!url) return null;
    const splitUrl = url.split('/');
    const filename = splitUrl[splitUrl.length - 1];
    const keyImagePart = filename.split('_')[0];
    return keyImagePart;
  };

  useEffect(() => {
    if (jsonData?.url) {
      const extracted = getExtractedValue(jsonData.url);
      setExtractedValue(extracted);
      console.log("Extracted value:", extracted);

      if (extracted) {
        const matchingItem = surgical_data.find(item => item.imagename.toString() === extracted);
        console.log("Matching data:", matchingItem);
        setMatchingDatavalues(matchingItem);

        if (!matchingItem) {
          console.log("No matching data found. Available imagenames:", surgical_data.map(item => item.imagename));
        }
      }
    }
  }, [jsonData]);



  return (
    <div className="flex flex-col h-screen w-screen bg-[#f5f6fa]">
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmitComment}
        isSubmitting={isSubmitting}
        error={error}
        comments={comments}
        setComments={setComments}
      />
      <ToastContainer />
      <div className="border border-solid rounded-md bg-white">
        <Navbar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <div className="flex-1 overflow-y-auto p-4">
          {patientData.loading ? (
            <div className="bg-white pt-4 grid gap-x-6 h-full justify-center items-center shadow-md rounded rounded-2xl p-8 bg-clean-white border box-border shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300">
              <div className="text-logo-blue inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : (
            <div className='grid grid-cols-4 gap-x-3 h-full sm:flex sm:flex-col'>
              <div className="flex flex-col gap-4 overflow-y-auto max-h-[calc(100vh-4rem)]">
              {extractedValue === '3594825' && (
        <div className="bg-white rounded-2xl p-6 border-2 border-[#ff2627]">
          <div className="mb-4">
            <label className="font-semibold text-[#4359A5]">Life Saver Pathology:</label>
            <p className="mt-2 text-sm text-black font-normal">
              Bowel perforation
            </p>
          </div>
          <div>
            <label className="font-semibold text-[#4359A5]">Life Saver Reason:</label>
            <p className="mt-2 text-sm text-black font-normal">
              Creates an opening in the intestinal wall, allowing the contents of the bowel to spill into the abdominal cavity, leading to infection, peritonitis, and potentially life-threatening complications if not treated promptly.
            </p>
          </div>
        </div>
      )}
                {type === 'completed' && (
                  <div className="bg-white rounded-2xl p-6">
                    <div className="mb-4">
                      <label className="font-semibold text-[#4359A5]">Surgical Pathology:</label>
                      {reasonData && reasonData.surgical ? (
                        <p className="mt-2 text-sm text-black font-normal">
                          {reasonData.surgical_pathology.join(", ")}
                        </p>
                      ) : (
                        <p className="mt-2 text-sm text-gray-500 tracking-[1px]">NA</p>
                      )}
                    </div>
                    <div>
                      <label className="font-semibold text-[#4359A5]">Reason:</label>
                      {reasonData && reasonData.Reason && reasonData.Reason.length > 0 ? (
                        reasonData.Reason.length === 2 ? (
                          <ul className="mt-2 text-sm list-disc">
                            {reasonData.Reason.map((reason, index) => (
                              <li key={index} className="mt-1">
                                {reason}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="mt-2 text-sm">{reasonData.Reason[0]}</p>
                        )
                      ) : (
                        <p className="mt-2 text-sm text-gray-500 tracking-[1px]">NA</p>
                      )}
                    </div>
                  </div>
                )}
                

                {shouldRender && (
                  <div className="bg-white rounded-2xl p-6">
                    <div className="mb-4">
                      <label className="font-semibold text-[#4359A5]">Surgical Pathology:</label>
                      {matchingDatavalues ? (
                        <p className="mt-2 text-sm text-black font-normal">
                          {matchingDatavalues.surgical_pathology.join(", ")}
                        </p>
                      ) : (
                        <p className="mt-2 text-sm text-gray-500 tracking-[1px]">NA</p>
                      )}
                    </div>
                    <div>
                      <label className="font-semibold text-[#4359A5]">Reason:</label>
                      {matchingDatavalues ? (
                        <ul className="mt-2 text-sm list-disc pl-5">
                          {matchingDatavalues.surgical_reason.map((reason, index) => (
                            <li key={index} className="mt-1">
                              {reason}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="mt-2 text-sm text-gray-500 tracking-[1px]">NA</p>
                      )}
                    </div>
                  </div>
                )}
                


                <div>
                  {jsonData?.url ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {parseJsonUrl(jsonData.url).map((imageUrl, index) => (
                        <div key={index} className="Key-image bg-transparent pb-8 rounded-xl">
                          <img
                            src={imageUrl}
                            alt={`Image ${index + 1}`}
                            className="max-w-full h-auto rounded-xl fade-in"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = './img/no-image.png';
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <img
                      src={Notavailable}
                      alt="Fallback Image"
                      className="max-w-full rounded-xl bg-[#f2f2f2] p-4"
                    />
                  )}
                </div>
              </div>

              <div className="col-span-3 shadow-md rounded-2xl p-8 pr-2 bg-clean-white border box-border shadow-[4px_4px_4px_rgba(0,0,0,0.2)] bg-white border-gray-300 overflow-y-auto sm:p-5">

                <div className="flex items-center text-dark-blue-60">
                  <div className="flex items-center gap-2">
                    <img
                      className="max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/img/back.svg"
                    />
                    <div
                      className="font-semibold inline-block cursor-pointer"
                      onClick={() => {
                        if (type === "completed") navigate("/list");
                        else navigate("/list");
                      }}
                    >
                      Back
                    </div>
                  </div>
                </div>
                <div className=" flex justify-between items-center">
                  <div className="relative w-[60%] mt-4 rounded-md border h-10 p-1 bg-gray-200">

                    <div className="relative w-full h-full flex items-center">
                      <div
                        onClick={() => setSelected("fullReport")}
                        className="w-full flex justify-center text-gray-400 cursor-pointer"
                      >
                        <button>Full Report</button>
                      </div>
                      <div
                        onClick={() => setSelected("findings")}
                        className="w-full flex justify-center text-gray-400 cursor-pointer"
                      >
                        <button>Findings</button>
                      </div>

                    </div>
                    <span
                      className={`
                      bg-white shadow text-sm flex items-center justify-center w-1/2 rounded h-[1.88rem] 
                      transition-all duration-150 ease-linear top-[4px] absolute
                      ${selected === "findings"
                          ? 'left-1/2 -ml-1 text-[#4359A5] font-semibold'
                          : 'left-1 text-[#4359A5] font-semibold'
                        }
                    `}
                    >
                      {selected === "findings" ? 'Findings' : 'Full Report'}
                    </span>

                  </div>
                  {/* <div className="action-container flex gap-2 items-center mt-4">
                    <div className="relative inline-block" ref={dropdownRef}>
                      <button
                        onClick={toggleDropdown}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                      >
                        <FaShare className="mr-2" />
                        <span>Share</span>
                      </button>
                      {isOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                          <div className="py-1">
                            <button
                              onClick={shareViaGmail}
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                            >
                              <FaEnvelope className="mr-2" />
                              Share via Gmail
                            </button>
                            <button
                              onClick={shareViaWhatsApp}
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                            >
                              <FaWhatsapp className="mr-2" />
                              Share via WhatsApp
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="relative inline-block group">
                      <button
                        className={`p-2 rounded-lg flex items-center justify-center space-x-2 relative overflow-hidden ${localPatientData?.action.case
                          ? 'bg-green-500 text-white'
                          : localPatientData?.action.feedback
                            ? 'bg-gray-400 text-white cursor-not-allowed'
                            : 'bg-gray-400 text-white hover:bg-green-500'
                          }`}
                        onClick={handleCheckClick}
                        disabled={localPatientData?.action.case || localPatientData?.action.feedback}
                      >
                        <span className="relative z-10 flex items-center justify-center space-x-2">
                          <FaCheck size={18} />
                          <span>{localPatientData?.action.case ? 'Case Accepted' : 'Accept Case'}</span>
                        </span>
                        <span className="fill-animation absolute inset-0 bg-green-500 transform scale-x-0 origin-left transition-transform duration-300 ease-out"></span>
                      </button>
                      <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
    bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
    opacity-0 group-hover:opacity-100 transition-opacity duration-300
    pointer-events-none">
                        {localPatientData?.action.case ? 'Case Accepted' : 'Accept Case'}
                      </p>
                    </div>
                    <div className="relative inline-block group">
                      <button
                        className={`p-2 rounded-lg ${localPatientData?.action.feedback
                          ? 'bg-red-500 text-white'
                          : localPatientData?.action.case
                            ? 'bg-gray-400 text-white cursor-not-allowed'
                            : 'bg-gray-400 text-white hover:bg-red-500'
                          }`}
                        onClick={handleSendFeedback}
                        disabled={localPatientData?.action.case || localPatientData?.action.feedback}
                      >
                        <span className="relative z-10 flex items-center justify-center space-x-2">
                          <MdOutlineQuestionMark size={22} />
                          <span> {localPatientData?.action.feedback ? 'Feedback Sent' : 'Send Feedback'}</span>
                        </span>
                      </button>
                      <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
              bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
              opacity-0 group-hover:opacity-100 transition-opacity duration-300
              pointer-events-none z-50">
                        {localPatientData?.action.feedback ? 'Feedback Sent' : 'Send Feedback'}
                      </p>
                    </div>
                  </div> */}
                </div>
                {selected === "fullReport" && (
                  <>
                    <div className="flex-grow text-center mt-8 text-[#4359A5] font-bold text-2xl">
                      <label className="font-semibold tracking-[0.5px]">
                        {isCompleted
                          ? transformLabel(patientData?.data?.mod_study)
                          : transformLabel(mod_study?.data?.data[0]?.mod_study)}
                      </label>
                    </div>
                    {history === '3644088' && (
                      <div className="history flex mt-8 items-center">
                        <label className="font-bold text-left text-xl text-[#4359A5]">History:&nbsp;</label>
                        <p className=" text-base mt-0">cough and pain</p>
                      </div>
                    )}



                    <div className="py-4">
                      <h3 className=" text-xl font-bold text-[#4359A5]">Observation</h3>
                      {jsonData.observations.map((item, index) => {
                        const [heading, observations] = Object.entries(item)[0];
                        return (
                          <div key={index} className="mb-2 mt-4">
                            {heading.toLowerCase() !== 'untitled' && (
                              <p className="font-semibold pb-1 text-md text-black pl-2">{heading}</p>
                            )}
                            <ul className="list-disc pl-8 text-gray-800 ">
                              {observations.map((observation, obsIndex) => (
                                <li key={obsIndex} className=" text-sm leading-6">{observation}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      })}
                    </div>

                    {tableData.length > 0 && (
                      <div className="overflow-x-auto pb-10">
                        <table className="min-w-[300px] w-auto max-w-[500px] border-collapse border border-gray-300 mt-2">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="border border-gray-300 p-2 text-sm font-semibold">Level</th>
                              <th className="border border-gray-300 p-2 text-sm font-semibold">AP diameter(mm)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td className="border border-gray-300 p-2 text-sm text-center">{row.Level}</td>
                                <td className="border border-gray-300 p-2 text-sm text-center">{row["AP diameter(mm)"]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="">
                      <p className="font-bold pb-1 text-xl text-[#4359A5]">Impression</p>
                      <ul className="list-disc pl-4 text-gray-700 pl-8">
                        {jsonData?.impression?.map((data, index) => (
                          <li key={index} className="text-sm leading-6" dangerouslySetInnerHTML={{ __html: highlightPathologies(data, truePathologies) }}></li>
                        ))}
                      </ul>
                    </div>

                    {patientData.data.order_id === '819584' && (
                      <div>
                        <p className="font-bold pb-1 text-xl text-[#4359A5] mt-5">Advice</p>
                        <p className=" text-sm leading-6">Clinical correlation</p>
                      </div>
                    )}

                  </>
                )}
                {selected === "findings" && (
                  <div className="mt-[3rem]">
                    <p className="font-bold pb-1 text-xl text-[#4359A5]">Findings</p>
                    <ul className="list-disc pl-4 font-bold text-gray-700">
                      <div className="grid grid-cols-3 gap-4 pt-4 sm:flex sm:flex-col">
                        {jsonData?.pathologies &&
                          Array.isArray(jsonData.pathologies) &&
                          jsonData.pathologies.length > 0 &&
                          Object.entries(jsonData.pathologies[0])
                            .filter(([key, value]) => value === true)
                            .map(([key, value], index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2 text-xl">
                                <IoCheckmarkCircle className="text-[#0a8807]" />
                                <span className="font-bold text-base">{key}</span>
                              </div>
                            ))}
                        {jsonData?.pathologies &&
                          Array.isArray(jsonData.pathologies) &&
                          jsonData.pathologies.length > 0 &&
                          Object.entries(jsonData.pathologies[0])
                            .filter(([key, value]) => value === false)
                            .map(([key, value], index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2 text-xl">
                                <IoCloseCircle className=" text-[#df1818]" />
                                <span className="font-normal text-base">{key}</span>
                              </div>
                            ))}
                      </div>
                    </ul>
                  </div>
                )}


              </div>
              <div className="absolute bottom-4 right-4 flex gap-2">
                <div className="relative inline-block" ref={dropdownRef}>
                  <button
                    onClick={toggleDropdown}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg rounded-br-none rounded-bl-none inline-flex items-center"
                  >
                    <FaShare className="mr-2" />
                    <span>Share</span>
                  </button>
                  {isOpen && (
                    <div className="absolute right-0 bottom-full mb-1 w-48 bg-white rounded-lg shadow-lg z-10">
                      <div className="py-1">
                        <button
                          onClick={shareViaGmail}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                          <FaEnvelope className="mr-2" />
                          Share via Gmail
                        </button>
                        <button
                          onClick={shareViaWhatsApp}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                          <FaWhatsapp className="mr-2" />
                          Share via WhatsApp
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative inline-block group">
                  <button
                    className={`p-2 rounded-lg rounded-br-none rounded-bl-none flex items-center justify-center space-x-2 relative overflow-hidden ${localPatientData?.action.case
                      ? 'bg-green-500 text-white'
                      : localPatientData?.action.feedback
                        ? 'bg-gray-400 text-white cursor-not-allowed'
                        : 'bg-gray-400 text-white hover:bg-green-500'
                      }`}
                    onClick={handleCheckClick}
                    disabled={localPatientData?.action.case || localPatientData?.action.feedback}
                  >
                    <span className="relative z-10 flex items-center justify-center space-x-2">
                      <FaCheck size={18} />
                      <span>{localPatientData?.action.case ? 'Case Accepted' : 'Accept Case'}</span>
                    </span>
                    <span className="fill-animation absolute inset-0 bg-green-500 transform scale-x-0 origin-left transition-transform duration-300 ease-out"></span>
                  </button>
                  <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
        bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
        opacity-0 group-hover:opacity-100 transition-opacity duration-300
        pointer-events-none">
                    {localPatientData?.action.case ? 'Case Accepted' : 'Accept Case'}
                  </p>
                </div>
                <div className="relative inline-block group">
                  <button
                    className={`p-2 rounded-tr-none rounded-tl-lg rounded-br-lg ${localPatientData?.action.feedback
                      ? 'bg-red-500 text-white'
                      : localPatientData?.action.case
                        ? 'bg-gray-400 text-white cursor-not-allowed'
                        : 'bg-gray-400 text-white hover:bg-red-500'
                      }`}
                    onClick={handleSendFeedback}
                    disabled={localPatientData?.action.case || localPatientData?.action.feedback}
                  >
                    <span className="relative z-10 flex items-center justify-center space-x-2">
                      <MdOutlineQuestionMark size={22} />
                      <span> {localPatientData?.action.feedback ? 'Feedback Sent' : 'Send Feedback'}</span>
                    </span>
                  </button>
                  <p className="absolute w-28 px-2 py-1 text-center text-xs text-white bg-black rounded-lg shadow-lg 
        bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
        opacity-0 group-hover:opacity-100 transition-opacity duration-300
        pointer-events-none z-50">
                    {localPatientData?.action.feedback ? 'Feedback Sent' : 'Send Feedback'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, onSubmit, isSubmitting, error, comments, setComments }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-1/3 relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <IoClose size={24} />
        </button>
        <h2 className="text-xl font-semibold mb-4">Send Feedback</h2>
        <textarea
          className="w-full p-2 border rounded text-black text-opacity-70 text-sm"
          rows="4"
          placeholder="Enter your feedback here..."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        ></textarea>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div className='flex gap-3 justify-end'>
          <button onClick={onClose} className="mt-4 bg-white text-red-500 px-4 py-2 font-normal border-[1.5px] border-solid border-red-500 rounded hover:bg-red-500 hover:text-white" disabled={isSubmitting}>
            Close
          </button>
          <button onClick={onSubmit} className="mt-4 bg-white text-[#1777ff] font-normal px-4 py-2 border-[1.5px] border-solid border-[#1777ff] rounded hover:bg-[#1777ff] hover:text-white" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};


export default Reports;